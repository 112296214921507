import Checkbox from './Checkbox.js';
import LoginButton from './LoginButton.js';
import Button from './Button.js';
import Text from './Text.js';
import Number from './Number.js';
import Select from "./Select";
import Date from "./Date";
import NestedForm from "./NestedForm";
import RouteStopSelector from "./RouteStopSelector";
import "./Forms.css";

const Forms = {};

Forms.Checkbox = Checkbox;
Forms.Button = Button;
Forms.LoginButton = LoginButton;
Forms.Text = Text;
Forms.Number = Number;
Forms.Select = Select;
Forms.Date = Date;
Forms.NestedForm = NestedForm;
Forms.RouteStopSelector = RouteStopSelector;

export default Forms;