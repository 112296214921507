import './Checkbox.css';

const inputMainProps = ['required', 'name', 'value', 'onChange', 'placeholder'];

function Checkbox(props) {

    const getMainProps = (elementProps) => {
        const result = {};
        Object.entries(props).forEach(([propName, value]) => {
            if (elementProps.indexOf(propName) !== -1) {
                if (propName === "value") {
                    if (value) {
                        result.checked = true;
                    }
                } else {
                    result[propName] = value
                }
            }
        });
        return result;
    }

    const handleChange = (e) => {
        props.onChange(null, e.target.name, e.target.checked);
    }

    return (
        <label className="form-checkbox">
            <input checked={props.value} type="checkbox" {...getMainProps(inputMainProps)} onChange={handleChange}/>
            {props.label}
        </label>
    );
}

export default Checkbox;