import * as React from "react";
import './Date.css';
import moment from "moment";

const inputMainProps = ['required', 'name', 'value', 'onChange', 'placeholder', 'min', 'max'];

function Date(props) {

    const getMainProps = (elementProps) => {
        const result = {};
        Object.entries(props).forEach(([propName, value]) => {
            if (propName === 'value') {
                value = moment(value).format('YYYY-MM-DD');
            } else if (propName === 'min' || propName === 'max') {
                value = moment(value).format('YYYY-MM-DD');
            }
            (elementProps.indexOf(propName) !== -1) && (result[propName] = value);
        });
        return result;
    }

    const handleChange = (e) => {
        props.onChange(null, e.target.name, e.target.value);
    }

    const disabled = props.disabled || false;

    return (
        <div className="FormInput FormDate">
            <label className={props.required ? 'required-label' : ''}>{props.label}</label>
            <input type="date" {...getMainProps(inputMainProps)} onChange={handleChange} disabled={disabled}/>
        </div>
    );
}

export default Date;