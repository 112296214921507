import config from "./config/config";

export const priceFormatter = (product, withoutVat) => {
    let {ourPrice, vat} = product;
    if (withoutVat) {
        vat = vat.value / 100;
        ourPrice = ourPrice - (ourPrice * vat);
    }
    return formatPrice(ourPrice);
};

export const formatPrice = (value) => value.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ');

export const getCurrency = (currencyCode) => {
    return config.currencyOptions.find(currency => currency.code === currencyCode);
}

export const objectId = function () {
    const timestamp = (new Date().getTime() / 1000 | 0).toString(16);
    return timestamp + 'xxxxxxxxxxxxxxxx'.replace(/[x]/g, function () {
        return (Math.random() * 16 | 0).toString(16);
    }).toLowerCase();
};

export const onChange = (setFormData) => (e, key, val) => {
    let value;
    let name;
    if (e?.constructor?.name === 'SyntheticBaseEvent') {
        name = e.target.name;
        switch (e.target.type) {
            case 'checkbox':
                value = e.target.checked;
                break;
            case 'number':
                value = e.target.value ? parseFloat(e.target.value) : '';
                break;
            case 'file':
                value = e.target.files;
                break;
            default:
                value = e.target.value;
        }
    } else {
        value = val;
        name = key;
    }

    setFormData(prevState => ({
            ...prevState,
            [name]: value
        }
    ));
}
