import * as React from "react";
import './Text.css';

const inputMainProps = ['required', 'name', 'value', 'onChange', 'placeholder'];

function Text(props) {

    const getMainProps = (elementProps) => {
        const result = {};
        Object.entries(props).forEach(([propName, value]) => {
            (elementProps.indexOf(propName) !== -1) && (result[propName] = value);
        });
        return result;
    }

    const handleChange = (e) => {
        props.onChange(null, e.target.name, e.target.value);
    }

    const disabled = props.disabled || false;

    return (
        <div className="FormInput FormText">
            <label className={props.required ? 'required-label' : ''}>{props.label}</label>
            <input type="text" {...getMainProps(inputMainProps)} onChange={handleChange} disabled={disabled}/>
        </div>
    );
}

export default Text;